export interface Configuration {
    defaultLanguage: string;
    availableLanguages: string[];
    theme: string;
    environmentId: string;
    version: string;
    windowTitle?: string;
    logo?: string;
    logoInverse?: string;
    avatarColors?: string[];
    logging: {
        logglyEnabled: boolean;
        logglyKey: string;
    };
    api: {
        duse: {
            baseUrl: string;
        },
        dericonId: {
            baseUrl: string;
        },
        mercury: {
            baseUrl: string;
            broker: string;
        },
        derifin: {
            baseUrl: string;
        }
    };
    featureFlags: {
        disableAppBar: boolean;
        disableTags: boolean;
        disableAttachments: boolean;
        disableSearch: boolean;
        disableNotifications: boolean;
        disableSettings: boolean;
        disableCommentEditing: boolean;
        minimizeProfile: boolean;
        disableAttachmentsPreview: boolean;
        disableReadByPopover: boolean;
        disableMustReady: boolean;
    };
}


export function createConfiguration(params: Partial<Configuration>) {
    return {
        defaultLanguage: 'de',
        availableLanguages: [
            'de',
            'en'
        ],
        environmentId: 'QQD3M0',
        theme: 'dericon',
        logging: {
            logglyEnabled: false,
            logglyKey: '',
        },
        api: {
            duse: {
                baseUrl: 'https://cloud.derif.in/duse/api'
            },
            dericonId: {
                baseUrl: 'https://id.dericon.it'
            },
            mercury: {
                baseUrl: 'https://mercury-current.dericon.it',
                broker: 'broker/api/v1/broker'
            },
            derifin: {
                baseUrl: 'https://derifin.dericon.it'
            }
        },
        featureFlags: {
            disableAppBar: true,
            disableTags: true,
            disableAttachments: false,
            disableSearch: false,
            disableNotifications: false,
            disableSettings: false,
            disableCommentEditing: false,
            minimizeProfile: true,
            disableAttachmentsPreview: false,
            disableReadByPopover: false,
            disableMustReady: false
        },
        ...params
    } as Configuration;
}

